
    ::v-deep table {
        tr {
            * {
                margin: 0;
            }
        }
    }
    .courseDetail-wrapper {
        width: 100%;
        height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        .back-wrapper {
            padding: 20px 0 15px;
            text-align: right;
            background: #fff;
        }
        .courseDetail-content {
            flex: 1;
            height: 1%;
            background: #9F9FFF;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    min-width: 1400px;
                }
            }
            .courseDetail-banner {
                height: 450px;
                background: #6F6DFC;
                position: relative;
                .bg-circle {
                    position: absolute;
                    left: 10px;
                    right: 0;
                    height: 96px;
                    background: url("../../assets/images/courseDetail/circle.png") repeat;
                }
                .bg-colorBlock {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 30px;
                    background: url("../../assets/images/courseDetail/colorBlock.png") repeat;
                }
                .bg-people {
                    position: absolute;
                    top: 0;
                    right: 150px;
                    width: 607px;
                    height: 448px;
                    background: url("../../assets/images/courseDetail/people.png") no-repeat;
                }
                .banner-content {
                    width: 1400px;
                    margin: 0 auto;
                    position: relative;
                    .course-name {
                        width: calc(100% - 607px);
                        padding-top: 110px;
                        padding-left: 10px;
                        font-size: 60px;
                        color: #fff;
                        font-weight: bold;
                        line-height: 1.5;
                        z-index: 1;
                        text-shadow: -5px 5px 0 #2E208F, 5px 5px 0 #2E208F, 5px -5px 0 #2E208F, -5px -5px 0 #2E208F;
                        /*-webkit-text-stroke: 3px #2E208F;*/
                    }
                    .toCourse-btn {
                        margin-top: 50px;
                        width: 262px;
                        height: 62px;
                        background: #B7FDB7;
                        border: 6px solid #2E208F;
                        padding-left: 54px;
                        line-height: 50px;
                        box-sizing: border-box;
                        font-size: 24px;
                        color: #2E208F;
                        font-weight: bold;
                        /*cursor: pointer;*/
                    }
                }
            }
            .courseDetail-item {
                &:last-child {
                    padding-bottom: 120px;
                }
                .item-caption {
                    width: 368px;
                    height: 80px;
                    line-height: 80px;
                    margin: 80px auto 0;
                    position: relative;
                    .text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 3;
                        font-size: 36px;
                        color: #fff;
                        font-weight: bold;
                        //text-shadow: -3px 3px 0 #2E208F, 3px 3px 0 #2E208F, 3px -3px 0 #2E208F, -3px -3px 0 #2E208F;
                        text-shadow: -2px 2px 0 #2E208F, 2px 2px 0 #2E208F, 2px -2px 0 #2E208F, -2px -2px 0 #2E208F;
                        /*-webkit-text-stroke: 1px #2E208F;*/
                    }
                    .item-caption-bg1 {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: 368px;
                        height: 80px;
                        box-sizing: border-box;
                        border-radius: 40px;
                        background: #F1D246;
                        border: 4px solid #2E208F;
                    }
                    .item-caption-bg2 {
                        position: absolute;
                        z-index: 1;
                        top: 12px;
                        left: 0;
                        width: 368px;
                        height: 80px;
                        box-sizing: border-box;
                        border-radius: 40px;
                        background: #E47192;
                        border: 4px solid #2E208F;
                    }
                }
                .item-content {
                    margin: 56px 68px 0;
                    position: relative;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: -15px;
                        width: 30px;
                        height: 30px;
                        background: #F1D246;
                        border-radius: 50%;
                        border: 4px solid #2E208F;
                        box-sizing: border-box;
                    }
                    &:before {
                        left: calc(50% - 214px);
                    }
                    &:after {
                        right: calc(50% - 214px);
                    }
                    .introduce-wrapper {
                        padding: 60px;
                        font-size: 18px;
                        text-indent: 2em;
                        background: #FFFDE8;
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        ::v-deep p {
                            margin: 0;
                        }
                    }
                    ::v-deep .content-table {
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        table {
                            border-collapse: collapse!important;
                            width: 100%!important;
                            border-color: transparent;
                            border-bottom: none;
                            margin-top: -4px;
                            tr {
                                height: auto!important;
                                th, td {
                                    border-right: 4px solid #fff;
                                    border-top: 4px solid #fff;
                                    &:first-child {
                                        border-left: 0;
                                        /*text-align: center;*/
                                    }
                                    &:last-child {
                                        border-right: 0;
                                        /*text-align: center;*/
                                    }
                                }
                                th {
                                    text-align: left;
                                    padding: 20px;
                                    height: 20px!important;
                                    line-height: 20px;
                                    box-sizing: border-box;
                                    font-size: 18px;
                                    color: #2A1E05;
                                    white-space: nowrap;
                                }
                                td {
                                    height: auto!important;
                                    text-align: left;
                                    padding: 20px;
                                    font-size: 18px;
                                    color: #444;
                                }
                            }
                        }
                        &.yellow-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #fffde8;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #f0edc6;
                                        }
                                    }
                                    th {
                                        background: #E3DD9B;
                                    }
                                }
                            }
                        }
                        &.blue-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #e7f7ff;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #D3F1FD;
                                        }
                                    }
                                    th {
                                        background: #B7E7FB;
                                    }
                                }
                            }
                        }
                        &.purple-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #E0E0F9;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #F2F2FC;
                                        }
                                    }
                                    th {
                                        background: #BABAFC;
                                    }
                                }
                            }
                        }
                        &.pink-table {
                            table {
                                tr {
                                    &:nth-child(even) {
                                        td {
                                            background: #FEE9EF;
                                        }
                                    }
                                    &:nth-child(odd) {
                                        td {
                                            background: #FAF2FE;
                                        }
                                    }
                                    th {
                                        background: #FABBCD;
                                    }
                                }
                            }
                        }
                    }
                    .relatedCourse-wrapper {
                        padding: 0 100px 20px 50px;
                        background: #FFFDE8;
                        border-radius: 20px;
                        overflow: hidden;
                        border: 4px solid #2E208F;
                        display: flex;
                        flex-wrap: wrap;
                        .relatedCourse-item {
                            margin-top: 40px;
                            margin-left: 50px;
                            width: calc(100% / 7 - 50px);
                            .course-cover {
                                width: 100%;
                                height: 0;
                                padding-bottom: 56.179775%;
                                overflow: hidden;
                                position: relative;
                                background: rgba(159, 159, 255, .2);
                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .course-name {
                                width: 100%;
                                margin: 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
